/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React, { useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";
import tw, { css, theme } from "twin.macro";
import { DocumentContext } from "~context/DocumentContext.jsx";
import * as T from "~components/styles/Typography.jsx";
import * as Icon from "~components/svg/Icons.jsx";
import Grid from "~components/styles/Grid.jsx";
import Image from "~components/Image.jsx";
import SwiperCarousel from "~components/SwiperCarousel.jsx";

const GallerySection = () => {
  // ===========================================================================
  // context / ref / state

  const { isDesktop, screen } = useContext(DocumentContext);

  // ===========================================================================
  // variables

  const { allSanityFeatures } = useStaticQuery(graphql`
    query GallerySection {
      allSanityFeatures {
        edges {
          node {
            slides {
              backgroundColor {
                hex
                title
              }
              fontColor {
                hex
                title
              }

              heading
              subheading
              body

              image {
                altText

                asset {
                  originalFilename
                  title
                  description

                  fluid {
                    ...GatsbySanityImageFluid_withWebp_noBase64
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  let slides;

  if (allSanityFeatures?.edges?.[0]) {
    allSanityFeatures.edges.forEach(({ node }) => {
      if (node?.slides?.[0] && !slides) {
        ({ slides } = node);
      }
    });
  }

  // ===========================================================================
  // render

  return (
    <div
      css={[
        css`
          .swiper-slide {
            height: auto;
          }
        `,
        tw`w-screen relative block`
      ]}
    >
      {slides?.[0] && (
        <SwiperCarousel
          nav={({ current, next, prev }) => (
            <div
              css={[
                tw`w-full absolute bottom-0 right-0 left-0 z-10 pb-8 md:pb-12`
              ]}
            >
              {screen && isDesktop() && (
                <Grid>
                  <div
                    css={[
                      tw`col-span-12 relative flex items-center justify-end mt-6 md:mt-10`
                    ]}
                  >
                    <button
                      type="button"
                      css={[
                        css`
                          @media not all and (pointer: coarse) {
                            &:hover {
                              background: ${theme`colors.black`};

                              svg {
                                polyline {
                                  stroke: ${theme`colors.white`};
                                }
                              }
                            }
                          }
                        `,
                        tw`w-8 h-8 relative block rounded-full overflow-hidden`
                      ]}
                      onClick={prev}
                    >
                      <Icon.ArrowButtonLeft />
                    </button>

                    <button
                      type="button"
                      css={[
                        css`
                          @media not all and (pointer: coarse) {
                            &:hover {
                              background: ${theme`colors.black`};

                              svg {
                                polyline {
                                  stroke: ${theme`colors.white`};
                                }
                              }
                            }
                          }
                        `,
                        tw`w-8 h-8 relative block ml-2 rounded-full overflow-hidden`
                      ]}
                      onClick={next}
                    >
                      <Icon.ArrowButtonRight />
                    </button>
                  </div>
                </Grid>
              )}

              {screen && !isDesktop() && (
                <div
                  css={[tw`w-full relative flex items-center justify-center`]}
                >
                  <ul css={[tw`flex`]}>
                    {slides.map((_, slideIndex) => {
                      const key = `gallery-swiper-bullet-${slideIndex}`;

                      return (
                        <li
                          key={key}
                          css={[
                            css`
                              background: ${current === slideIndex
                                ? theme`colors.black`
                                : `transparent`};
                            `,
                            tw`w-3 h-3 relative block rounded-full mx-1 border-black border`
                          ]}
                        />
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          )}
          slides={slides.map((slide, slideIndex) => {
            const key = `gallery-slide-${slideIndex}`;

            return (
              <li key={key} css={[tw`h-full relative`]}>
                <article
                  css={[
                    css`
                      transition: transform 0.3s ease;

                      flex: 1 0 auto;
                      background-color: ${slide?.backgroundColor?.hex ||
                      theme`colors.purple`};
                      color: ${slide?.fontColor?.hex || theme`colors.black`};
                    `,
                    tw`w-full h-full pt-8 md:pt-8 pb-24 md:pb-12 md:pb-32`
                  ]}
                >
                  <Grid>
                    <div
                      css={[
                        tw`col-span-12 md:col-span-6 flex flex-col justify-between`
                      ]}
                    >
                      <T.Heading
                        font={isDesktop() ? `2` : `3`}
                        level="3"
                        inject={{ tw: tw`mb-8 md:mb-0` }}
                      >
                        {slide.heading}
                      </T.Heading>

                      {isDesktop() && (
                        <div>
                          <T.Heading font="2" level="3">
                            {slide.subheading}
                          </T.Heading>

                          <T.Body
                            inject={{
                              css: `
                                font-weight: 300;
                                
                                a {
                                  text-decoration: underline;
                                }`,
                              tw: tw`mt-8 whitespace-pre-wrap`
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{ __html: slide.body }}
                            />
                          </T.Body>
                        </div>
                      )}
                    </div>

                    <div
                      css={[
                        tw`col-span-8 md:col-span-5 col-start-5 md:col-start-8 relative flex overflow-hidden`
                      ]}
                    >
                      <div
                        css={[
                          css`
                            padding-bottom: 100%;
                          `,
                          tw`w-full relative block`
                        ]}
                      >
                        <figure
                          css={[
                            tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center overflow-hidden`
                          ]}
                        >
                          <Image
                            image={slide.image}
                            inject={{
                              tw: tw`w-full h-full relative block object-cover`
                            }}
                          />
                        </figure>
                      </div>
                    </div>

                    {!isDesktop() && (
                      <div css={[tw`col-span-11 mt-8`]}>
                        <T.Heading font="3" level="3">
                          {slide.subheading}
                        </T.Heading>

                        <T.Body
                          font="2"
                          inject={{ css: `font-weight: 300`, tw: tw`mt-5` }}
                        >
                          {slide.body}
                        </T.Body>
                      </div>
                    )}
                  </Grid>
                </article>
              </li>
            );
          })}
        />
      )}
    </div>
  );
};

export default GallerySection;
